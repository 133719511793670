<template>
  <collapsible-card :compact-header="true" :visible="visible">
    <template #header>
      <strong>Account Budget Manager Activity</strong>
    </template>
    <div class="row">
      <div class="col-8 d-flex-center-center mb-2">
        <b-btn variant="link"
               @click="dateRange.monthIndex++">
          <fluency-icon type="arrowLeft" /> Previous Month
        </b-btn>
        <div class="d-inline pl-3 pr-3 h5">
          <b>Viewing {{dateRange.monthValue}}<template v-if="currentYear">, {{currentYear}}</template></b>
        </div>
        <b-btn variant="link"
               :disabled="dateRange.monthIndex === 0"
               @click="dateRange.monthIndex--">Next Month <fluency-icon type="arrowRight" /></b-btn>
      </div>
    </div>
    <div class="row" v-if="activity.length > 0" >
      <div class="col-8">
        <render-chart :chart-data="chartData"
                      :options="chartOptions"
                      :update-chart="updateChart"
                      :width="400"
                      :height="200"
                      chart-type="line" />
      </div>
      <div class="col-4" v-if="chartPointData">
        <span :key="`chartItem${chartIndex}`" v-for="(chartPoint, chartIndex) in chartPointData" :class="{'target-today': chartPoint.includes('Target'), 'actual-today': chartPoint.includes('Actual')}">
          {{chartPoint}}<br/>
        </span>
      </div>
    </div>
    <t-row v-else>
      <t-col cols="8">
        <p class="alert alert-info text-center my-5">No data to display for this time period.</p>
      </t-col>
    </t-row>
  </collapsible-card>
</template>

<script>
import moment from 'moment'
import { branding } from 'core-ui/assets/js/constants/constants-palette.js'
import CollapsibleCard from './widgets/collapsibleCard'
import RenderChart from 'core-ui/components/reporting/renderChart'

export default {
  name: 'accountBudgetManagerActivity',
  components: { RenderChart, CollapsibleCard },
  props: ['budgetPlanId', 'visible'],
  data () {
    return {
      activity: [],
      chartPointData: '',
      activityFields: ['approvedBudgetAmount', 'numberOfBudgets', 'totalAdjustments', 'dateOfCheck', 'dateOfCheckInZone', 'spendAsOfYesterday', 'spendTotal'],
      dateRange: {
        start: null,
        end: null,
        monthIndex: 0,
        monthValue: this.$moment(new Date()).format('MMMM')
      },
      updateChart: 0,
      chartData: {
        labels: [ // Date Objects
          1,
          2,
          3,
          4,
          5
        ],
        datasets: [{
          label: 'Target Today',
          backgroundColor: branding.fluencyGreen,
          borderColor: branding.fluencyGreen,
          fill: false,
          data: [
            1, 2, 3, 4, 5
          ],
          pointRadius: 1,
          pointBackgroundColor: branding.fluencyGreenFaded
        }]
      },
      chartOptions: {
        plugins: {},
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        scales: {
          y: {
            ticks: {
              callback: function (label, index, labels) {
                return `$${label}`
              }
            }
          },
          x: {
            ticks: {
              callback: function (label, index, labels) {
                return moment(this.getLabelForValue(label)).utc().format('M/D h:mma')
              }
            }
          }
        }
      }
    }
  },
  watch: {
    'dateRange.monthIndex' (newValue, oldValue) {
      const activeMonth = this.$moment(new Date()).subtract(newValue, 'months')
      this.$setCompat(this.dateRange, 'monthValue', activeMonth.format('MMMM'))
      this.dateRange.start = activeMonth.startOf('month').format('YYYY-MM-DD')
      this.dateRange.end = activeMonth.endOf('month').format('YYYY-MM-DD')
      this.initGraph(this.dateRange.start, this.dateRange.end)
    }
  },
  computed: {
    account () {
      return this.$store.getters.settingsItem || this.$store.getters.activeItem
    },
    currentYear () {
      return this?.dateRange?.start?.split('-')?.[0]
    }
  },
  async mounted () {
    this.initGraph()
  },
  methods: {
    async initGraph (start, end) {
      let labels = []
      let data = []
      let response = {}

      if (!start || !end) {
        this.dateRange.start = this.$moment().startOf('month').format('YYYY-MM-DD')
        this.dateRange.end = this.$moment().endOf('month').format('YYYY-MM-DD')
      } else {
        this.dateRange.start = start
        this.dateRange.end = end
      }

      if (!this.budgetPlanId) {
        response = await this.$res.fetch.accountPlanBudgetMonitor(`?start=${this.dateRange.start}&end=${this.dateRange.end}`, {
          accountPlanId: this.account.accountPlanId
        })
        this.$setCompat(this, 'activity', response)
        labels = response.map(x => x.dateOfCheckInZone)
        data = response.map(x => x.spendTotal)
        this.drawGraph(response, labels, data)
      } else if (Number.isInteger(this.budgetPlanId)) {
        response = await this.$res.fetch.budgetPlanBudgetMonitor(`?start=${this.dateRange.start}&end=${this.dateRange.end}`, {
          budgetPlanId: this.budgetPlanId
        })
        this.$setCompat(this, 'activity', response)
        labels = response.map(x => x.date)
        data = response.map(x => x.targetToday)
        const data2 = response.map(x => x.actualToday)
        this.drawGraph(response, labels, data, data2)
      }
    },
    formatDataItem (value, label) {
      const lowerLabel = label.toLowerCase()
      if (lowerLabel.includes('spend') || lowerLabel.includes('amount') || lowerLabel.includes('budget') || lowerLabel.includes('today') || lowerLabel.includes('partnerallocation')) {
        return this.$filters.currency(value, 0)
      } else if (lowerLabel.includes('date')) {
        return this.$moment.utc(new Date(value)).format('M/D h:mma')
      }
      return value
    },
    drawGraph (response, labels, data, data2) {
      const v = this
      const chartData = { ...this.chartData }
      const chartOptions = { ...this.chartOptions }

      chartData.labels = labels
      chartData.datasets[0].data = data

      if (data2) {
        chartData.datasets[1] = {
          label: 'Actual Today',
          backgroundColor: branding.fluencyPink,
          borderColor: branding.fluencyPink,
          fill: false,
          data: data2,
          pointRadius: 1,
          pointBackgroundColor: branding.fluencyPink
        }
      }

      chartOptions.plugins.tooltip = {
        displayColors: false,
        titleColor: 'transparent',
        backgroundColor: 'transparent',
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.dataIndex) {
              const labelData = response[tooltipItem.dataIndex]
              const labelText = []
              let labelsToRender = []
              if (labelData.budgetManagerDailyPartnerPlanId) {
                labelsToRender = ['actualToday', 'dailySpendToday', 'date', 'exhausted', 'optimizerStrategy', 'partnerAllocation', 'targetToday', 'totalMonthBudget']
              } else {
                labelsToRender = ['approvedBudgetAmount', 'numberOfBudgets', 'totalAdjustments', 'dateOfCheckInZone', 'spendAsOfYesterday', 'spendTotal']
              }
              for (const label in labelData) {
                // check for clickType, if clickType is an array we are not deep enough to show it
                // if clickType is a value then we can show it
                if (typeof labelData[label] !== 'object') {
                  if (label === 'device' && v.showBreadcrumb) {
                    continue
                  }
                  if (labelsToRender.includes(label)) {
                    labelText.push(`${v.$filters.convertFromCamelCase(label.replace('dateOfCheckInZone', 'date'))}: ${v.formatDataItem(labelData[label], label)}`)
                  }
                }
              }
              // return labelText
              v.$setCompat(v, 'chartPointData', labelText)
            }
          },
          title: function () {
            return ''
          }
        }
      }

      this.$setCompat(this, 'chartData', chartData)
      this.$setCompat(this, 'chartOptions', chartOptions)
      this.$setCompat(this, 'updateChart', this.updateChart + 1)
    }
  }
}
</script>

<style type="scss" scoped>
.target-today:before {
  content: ' ';
  color: rgb(153, 184, 152);
  background-color: rgb(153, 184, 152);
  border: 1px solid;
  display: inline-block;
  height: 0.75em;
  width: 0.75em;
  border-radius:0.2em;
}
.actual-today:before {
  content: ' ';
  color: rgb(255, 132, 124);
  background-color: rgb(255, 132, 124);
  border: 1px solid;
  display: inline-block;
  height: 0.75em;
  width: 0.75em;
  border-radius:0.2em;
}
</style>
