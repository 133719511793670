<template>
  <div>
    <collapsible-card :compact-header="true" v-if="automationLog && automationLog.length > 0">
      <template #header>
        <strong>Account Automation Log</strong>
      </template>
      <collapsible-card :key="`automationLogItem${automationItem.automationLogId}`" v-for="automationItem in automationLog" :compact-header="true" @shown="showDetail(automationItem.automationLogId)">
        <template #header>
          {{automationItem.source}}
          <div class="float-right">
            <fluency-icon type="clock" class="text-sm-left"></fluency-icon>
            {{$moment.utc(automationItem.dateTime).local().format('MMMM Do, h:mm a')}}
          </div>
        </template>
        <automation-log-detail-activity v-if="shownItems.includes(automationItem.automationLogId)" :automation-log-id="automationItem.automationLogId"></automation-log-detail-activity>
      </collapsible-card>
    </collapsible-card>
    <t-alert v-else show class="m-4">
      No automation log activity for this account.
    </t-alert>
  </div>
</template>

<script>
import CollapsibleCard from '../../common/widgets/collapsibleCard'
import AutomationLogDetailActivity from './automationLogDetailActivity'

export default {
  name: 'automationLogActivity',
  components: { AutomationLogDetailActivity, CollapsibleCard },
  data () {
    return {
      automationLog: [],
      shownItems: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const currentDateTime = this.$moment(new Date())
      const endDate = currentDateTime.format('YYYY-MM-DD')
      const startDate = currentDateTime.subtract(1, 'months').format('YYYY-MM-DD')
      const account = this.$store.getters.settingsItem || this.$store.getters.activeItem

      if (account) {
        const response = await this.$res.fetch.automationLog(`?start=${startDate}&end=${endDate}`, {
          accountPlanId: account.accountPlanId
        })
        this.$setCompat(this, 'automationLog', response)
      }
    },
    showDetail (showItem) {
      this.shownItems.push(showItem)
    }
  }
}
</script>

<style scoped>

</style>
