<template>
  <div>
    <table>
      <tr :key="`logItem${logItem.objectId}`" v-for="logItem in detail">
        <td class="pt-4" valign="top">
          <div v-if="logItem.action" class="d-block"><strong>Action:</strong> {{logItem.action}}</div>
          <div class="d-block"><strong>Type:</strong> {{logItem.objectType}}</div>
        </td>
        <td class="pl-4 pt-4" valign="top">
          <strong class="d-block">Change Description:</strong>
          <div :key="`changeDescription${changeIndex}`" v-for="(changeDesc, changeIndex) in changeDescription(logItem.changeDescription)">
            {{changeDesc}}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'automationLogDetailActivity',
  props: ['automationLogId'],
  data() {
    return {
      detail: {}
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      let response = await this.$res.fetch.automationLogDetail({
        automationLogId: this.automationLogId
      })
      this.$setCompat(this, 'detail', response)
    },
    changeDescription (changeList) {
      if (changeList.includes(';')) {
        return changeList.split(';')
      } else {
        return changeList.split('\n')
      }
    }
  }
}
</script>

<style scoped>
</style>
