<template>
  <div v-if="settingsItem">

    <drawer :show="show"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            @update:show="show = $event, close()"
            :right="true"
            title="Account QA Status">
      <fluency-loader v-if="loading"></fluency-loader>
      <template v-else>
        <div class="d-flex-center-start w-100">
          <div>
            <b-checkbox :checked="showInactive" @input="showInactive = $event" switch>Show Details for Inactive</b-checkbox>
          </div>
          <div class="flex-grow-1 text-right">
            <fluency-loader v-if="isReloadingAccountQa" inline dots color />
          </div>
          <b-button variant="link" @click="reloadAccountQa" class="ml-2"><fluency-icon type="reload"/> Refresh</b-button>
        </div>
        <div :key="`blueprint-key-${indx}`" v-for="(blueprint, indx) in accountQa.subscriptionQAList">
          <div class="border mt-2 mb-4 px-3 py-2">
            <p class="text-125 mb-2 font-weight-bold text-primary">{{blueprint.subscribedTemplate}}{{(blueprint.forcedPaused)?' - [FORCED PAUSED]':''}}</p>
            <p v-if="blueprint.whoLaunched" class="mb-3">
              <!-- check for the no blueprint scenario -->
              Launched by {{blueprint.whoLaunched}}
            </p>
            <div :key="`blueprintElementQa${elementCount}`" class="border mb-2 px-3 py-2" v-for="(element, elementCount) in blueprint.elementQA">
              <div class="d-flex">
                <p class="text-110 mb-0"><strong>{{element.elementName}}</strong>&nbsp;({{element.total}})</p>
                <div class="flex-grow-1 text-right text-danger font-weight-bold">{{elementPct(element)}}</div>
              </div>

              <div>
                <span v-html="elementLanguage(element)"></span>
                <template v-if="element.failureReasons && Object.keys(element.failureReasons).length > 0">
                  <hr/>
                  <strong>Failure Reason</strong><br/>
                  {{Object.keys(element.failureReasons)[0]}}
                  ({{Object.values(element.failureReasons)[0]}})
                </template>
              </div>

              <template v-if="showInactive || element.enabledFailedToPublish > 0">
                <t-button-inline v-if="element.failedToPublish > 0" @click="getAccountQaDetails(blueprint.contentTemplateId, element.elementName)">View More Info
                  <fluency-icon type="chevronDown" v-if="accountQaDetails[blueprint.contentTemplateId] && accountQaDetails[blueprint.contentTemplateId][element.elementName] && accountQaDetails[blueprint.contentTemplateId][element.elementName].show"/>
                  <fluency-icon type="chevronRight" v-else/>
                </t-button-inline>
                <div v-if="accountQaDetails[blueprint.contentTemplateId] && accountQaDetails[blueprint.contentTemplateId][element.elementName] && accountQaDetails[blueprint.contentTemplateId][element.elementName].show"
                     class="mt-4">

                  {{ $filters.hide(shownErrors = 0) }}
                  <div v-for="(detail, detailIndex) in accountQaDetails[blueprint.contentTemplateId][element.elementName].groupedDetails" :key="`account-qa-detail-${detailIndex}`">
                    <div v-if="detailIndex !== 'more' && (showInactive || detail.enabledFailedToPublish)">
                      {{ $filters.hide(shownErrors += 1) }}
                      <template v-if="detail.objectName && detail.objectName.includes('textAssets')">
                        <nuxt-link :to="getElementLink(element, detail)">{{getCreativeHeadline(detail.objectName)}} (id: {{detail.objectId}})</nuxt-link>
                      </template>
                      <template v-else>
                        <nuxt-link :to="getElementLink(element, detail, accountQaDetails[blueprint.contentTemplateId][element.elementName])">{{detail.objectName}} (id: {{detail.objectId}})</nuxt-link>
                      </template>

                      <ul v-if="detail.errors">
                        <li v-for="(error, errorIndex) in detail.errors"
                            :key="`d${detailIndex}-${errorIndex}`"
                            class="">{{error}}</li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="numberErrorsToShow < shownErrors && accountQaDetails[blueprint.contentTemplateId][element.elementName].groupedDetails.more === true"
                       class="mb-4 font-italic text-muted">More errors present, only showing the first {{numberErrorsToShow}}...</div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="!accountQa.subscriptionQAList">
          No applicable QA items are active for this account.
        </div>
      </template>
    </drawer>
  </div>
</template>

<script>
import Drawer from 'core-ui/components/common/drawer.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'

export default {
  name: 'accountQaStatus',
  components: { FluencyLoader, Drawer },
  props: {
    showAccountQa: {},
    manageV2ListingPage: {
      // used to differentiate between main page and drawer content
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      accountQa: {},
      loading: false,
      accountQaDetails: {},
      isReloadingAccountQa: false,
      showInactive: false,
      numberErrorsToShow: 40,
      highestFailPct: 0
    }
  },
  watch: {
    showAccountQa (newValue, oldValue) {
      this.show = newValue
    },
    show: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getAccountQa()
        }
      }
    },
    accountPlanId () {
      if (this.show) {
        this.getAccountQa()
      } else {
        this.accountQa = {}
        this.accountQaDetails = {}
      }
    },
    hasQAIssues: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.highestFailPct < 1) {
            this.$emit('hasIssues', 'light')
            return
          }
          if (this.highestFailPct < 5) {
            this.$emit('hasIssues', 'info')
            return
          }
          if (this.highestFailPct < 10) {
            this.$emit('hasIssues', 'warning')
            return
          }
          this.$emit('hasIssues', 'danger')
        }
      },
      immediate: true
    }
  },
  computed: {
    drawerResizable () {
      if (this.manageV2ListingPage) {
        return true
      }
      return false
    },
    drawerWidth () {
      if (!this.manageV2ListingPage) {
        return 'calc(100% - 20px)'
      }

      return '550px'
    },
    drawerNoScrim () {
      return true
    },
    settingsItem () {
      return this.$store.getters.settingsItem || this.$store.getters.activeItem
    },
    accountPlanId () {
      return this.settingsItem?.accountPlanId
    },
    hasQAIssues () {
      if (this.accountQa && this.accountQa.subscriptionQAList) {
        if (this.accountQa.subscriptionQAList.flatMap(x => x.elementQA).filter(x => x.failedToPublish > 0).length > 0) {
          // return true
          return this.highestFailPct
        }
      }
      return false
    },
    showNotification () {
      return !!this.hasQAIssues
    }
  },
  methods: {
    getElementPath (element) {
      let elmNamePath = element.elementName.replace(/\s/g, '').replace('Creatives', 'Ads').replace('UserLists', 'Accounts')
      elmNamePath = elmNamePath.toLowerCase().substring(elmNamePath, elmNamePath.length - 1)
      return {
        path: `/manage/${elmNamePath}/`,
        name: elmNamePath
      }
    },
    getElementLink (element, detail, account) {
      const elm = this.getElementPath(element)
      let query = { id: detail.objectId }
      if (elm.name === 'ad' || elm.name === 'keyword') {
        query.table = elm.name
      }
      if (element.elementName === 'User Lists') {
        query = { id: account.accountPlanId, form: 'userlists' }
      }
      const link = { path: elm.path, query: query }
      return link
    },
    close () {
      this.show = false
      this.$emit('update:show-account-qa', false)
    },
    async getAccountQa (forceFetch = false) {
      this.loading = true
      const accountPlanId = this.accountPlanId
      if (accountPlanId) {
        const response = await this.$store.dispatch('fetchAccountQaStatus', { accountPlanId, forceFetch })

        // let response = await this.$res.fetch.accountQa(accountPlanId)
        this.accountQa = response
      }
      this.loading = false
    },
    async reloadAccountQa () {
      this.isReloadingAccountQa = true
      await this.getAccountQa(true)
      setTimeout(() => { this.isReloadingAccountQa = false }, 1000)
    },
    async getAccountQaDetails (strategyId, type) {
      if (this.accountQaDetails[strategyId] && this.accountQaDetails[strategyId][type] && this.accountQaDetails[strategyId][type].show) {
        this.accountQaDetails[strategyId][type].show = false
        return
      }
      const response = await this.$res.fetch.accountQaDetails({
        accountPlanId: this.accountPlanId,
        forStrategyId: strategyId,
        forType: type
      })
      if (!this.accountQaDetails[strategyId]) {
        this.accountQaDetails[strategyId] = {}
      }

      const groupedDetails = { more: false }

      response?.details.forEach(d => {
        if (Object.keys(groupedDetails).length > this.numberErrorsToShow) {
          groupedDetails.more = true
          return
        }

        if (!groupedDetails.hasOwnProperty(d.objectId)) {
          groupedDetails[d.objectId] = {
            objectId: d.objectId,
            objectName: d?.objectName,
            errors: [],
            enabledFailedToPublish: false
          }
        }

        if (!groupedDetails[d.objectId].errors.includes(d.error)) {
          groupedDetails[d.objectId].errors.push(d.error)
        }

        if (d.status === 'ENABLED') {
          groupedDetails[d.objectId].enabledFailedToPublish = true
        }
      })

      this.accountQaDetails[strategyId][type] = {
        ...response,
        groupedDetails,
        show: true
      }
    },
    elementPct (elm) {
      const total = elm.total
      const failed = (this.showInactive) ? elm.failedToPublish : elm.enabledFailedToPublish
      const pct = failed / total
      const percentFailed = this.$filters.percentage(pct, 0)
      const percentText = (failed > 0) ? `${percentFailed} failing QA` : ''

      if (!isNaN(pct) && pct > this.highestFailPct) {
        this.highestFailPct = pct
      }

      return percentText
    },
    elementLanguage (elm) {
      const failed = elm.failedToPublish
      const enabledFailed = elm.enabledFailedToPublish
      const disabledFailed = elm.failedToPublish - elm.enabledFailedToPublish
      const errorTxt = (failed > 1) ? 'Errors' : 'Error'

      if (failed < 1) {
        return '<span class="text-muted font-italic">no detected issues</span>'
      }

      // if (this.showInactive) {
      //   return `<b>${failed} ${errorTxt}</b> (${enabledFailed} active / ${disabledFailed} inactive)`
      // }
      //
      // return `${failed} ${errorTxt} (<b>${enabledFailed} active</b> / ${disabledFailed} inactive)`
      return `${failed} ${errorTxt} (${enabledFailed} active / ${disabledFailed} inactive)`
    },
    getCreativeHeadline (jsonText) {
      try {
        const creative = JSON.parse(jsonText)
        const creativeName = creative.textAssets.filter(asset => asset.locationInAd === 'headline1')[0].text
        return creativeName
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
