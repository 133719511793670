<template>
  <div>
    <automation-log-activity/>
    <account-budget-manager-activity/>
    <collapsible-card :compact-header="true" v-if="reverseSyncStatus && reverseSyncStatus.accountPlanId && reverseSyncStatus.details.length > 0">
      <template #header>
        <strong>Reverse Sync:
          <span v-if="!reverseSyncStatus.failed && !reverseSyncStatus.complete" class="text-warning">In Progress</span>
          <span v-if="!reverseSyncStatus.failed && reverseSyncStatus.complete" class="text-success">Completed Successfully</span>
          <span v-if="reverseSyncStatus.failed && reverseSyncStatus.complete" class="text-danger">Failed</span>
        </strong>
        <div class="float-right">
          <fluency-icon type="clock" class="text-sm-left"></fluency-icon>
          {{$moment.utc(reverseSyncStatus.completedAt).local().format('MMMM Do, h:mm a')}}
        </div>
      </template>
      <dl>
        <dt>{{$moment.utc(reverseSyncStatus.startedAt).local().format('MMMM Do, h:mm:ssa')}}</dt>
        <dd>Reverse sync started</dd>
        <template v-for="(details, rSIndex) in reverseSyncStatus.details" :key="rSIndex">
          <dt>{{$moment.utc(details.dateTime).local().format('h:mm:ssa')}}</dt>
          <dd>{{details.message}}</dd>
        </template>
        <template v-if="reverseSyncStatus.complete">
          <dt>{{$moment.utc(reverseSyncStatus.completedAt).local().format('MMMM Do, h:mm:ssa')}}</dt>
          <dd>Reverse sync
            <template v-if="reverseSyncStatus.failed"> failed</template>
            <template v-else> completed successfully</template>
          </dd>
        </template>
        <template v-else>
          <dt><fluency-icon type="loop" class="rotating p-0 m-0" v-p-tooltip="'Reverse Sync in Progress'" /></dt>
          <dd class="loading">Reverse Sync in Progress</dd>
        </template>
      </dl><br/>
    </collapsible-card>
    <t-alert v-else-if="!this.loading" class="m-4">
      No reverse sync status for this account.
    </t-alert>
  </div>
</template>

<script>
import CollapsibleCard from './widgets/collapsibleCard'
import AccountBudgetManagerActivity from './accountBudgetManagerActivity'
import AutomationLogActivity from '../pages/manage/automationLogActivity'

export default {
  name: 'accountActivity',
  components: {
    AutomationLogActivity,
    AccountBudgetManagerActivity,
    CollapsibleCard
  },
  data () {
    return {
      loading: false,
      reverseSyncStatus: {}
    }
  },
  mounted () {
    this.checkStatus()
  },
  computed: {
    account () {
      return this.$store.getters.settingsItem || this.$store.getters.activeItem
    }
  },
  methods: {
    async checkStatus () {
      this.$setCompat(this, 'loading', true)
      const response = await this.$res.fetch.reverseSyncStatus({
        accountPlanId: this.account.accountPlanId
      })
      if (response) {
        this.$setCompat(this, 'reverseSyncStatus', response)
        if (!response.complete && typeof response.complete === 'boolean') {
          setTimeout(() => {
            this.checkStatus()
          }, 10000)
        } else {
          this.$setCompat(this, 'loading', false)
        }
      }
    },
    formatDateTime (date) {
      // let dateTime = new Date(date)
      // TODO: fix this with moment
      return date
    }
  }
}
</script>

<style scoped>
dd:before {
  content:'\e153';
  position: relative;
  top: 1px;
  display: inline-block;
  speak:none;
  font-family: 'iconic-md';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 1em;
  margin-left: -0.5em;
  color: grey;
}
dd.loading:before {
  color: white;
}
dt {
  display: block;
  clear: left;
  float: left;
  width: 15em;
  text-align: right;
  margin-right: 1.5em;
}
dd {
  display: block;
  clear: right;
}
dd:after {
  border-right: 1px solid #eee;
  height: 1em;
  display: block;
  width: 1em;
  content: ' ';
  margin-left: 15.5em;
}
dd:last-of-type:after {
  border: none;
}

</style>
