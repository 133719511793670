<template>
  <div class="toolbox-dropdown" id="toolbox-container"
       @click="trackToolboxEvents($event)">
    <div class="white-space-no-wrap">
      <!-- TOOLS BUTTON DROPDOWN -->
      <b-dropdown ref="toolboxDropdown" :id="dropdownId"
                                        class="shortcut-bulk-coachmark"
                                        :toggle-class="{'p-0': buttonStyle}"
                                        :variant="buttonStyle ? 'link-info' : 'light'"
                                        :no-caret="buttonStyle" >
        <template #button-content>
          <template v-if="buttonStyle">
            <action-icon icon="toolbox" buttonText="Tools" caret/>
          </template>
          <template v-else>
            <fluency-icon type="toolbox" />
            Tools
            <fluency-icon v-if="accountDownloading" type="loop" class="rotating"></fluency-icon>
          </template>
        </template>

        <b-dropdown-form>
          <b-dropdown-item v-if="canShowTool('readOnly')" @click="toggleReadOnly">
            <template v-if="isReadOnly">Turn off Read-Only</template>
            <template v-if="!isReadOnly">Mark <span class="text-capitalize">{{ planType }}</span> as
                Read-Only</template>
            <info-tooltip v-if="planType === 'account'"
              title="Read-Only mode allows Fluency to measure, analyze, and report on your account, but does not allow publishing externally to partners."></info-tooltip>
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowHint('readOnly')" disabled>
            Open <span class="text-capitalize">{{ planType
            }}</span>
              Settings to toggle Read-Only mode.
          </b-dropdown-item>

          <b-dropdown-item @click="advancedBulkEdit()">
            Bulk Manage
          </b-dropdown-item>

          <paste-plans v-if="canShowTool('pastePlans')"
            dropdown-item
            :settings-type="planType" />

          <!-- there's some logic behind this one, so emit an event out -->
          <b-dropdown-item v-if="canShowTool('settingsReplicator')"
            @click="showSettingsReplicator"
            v-shortkey="['meta', 'shift', 's']"
            @shortkey="showSettingsReplicator"
            v-p-tooltip.top="'Replicate this campaign\'s settings to other campaigns'">
            Settings Replicator <shortkey-view :value="['meta', 'shift', 's']" />
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('blockedKeywords')"
            variant="ghost-light"
            v-p-tooltip.top="'Review and Fix Blocked Keywords for this account'"
            @click="showBlockedKeywords = true">
            Blocked Keywords
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('keywordRecs')"
            v-p-tooltip.top="'Accept or Reject Keyword Recommendations'"
            @click="showKeywordRecs = true">
            Keyword Recommendations
          </b-dropdown-item>

          <div v-if="canShowTool('download') || canShowTool('sdfDownload')">
            <template v-if="canShowTool('download') && canShowTool('sdfDownload')">
              <span class="mr-2">Download:</span>
              <b-dropdown-item v-if="canShowTool('download')"
                v-p-tooltip.top="'Download this account and all child components into an xls file'"
                class="px-2 mr-3"
                @click="downloadAccount()">XLS</b-dropdown-item>
              <b-dropdown-item v-if="canShowTool('sdfDownload')"
                v-p-tooltip.top="`Download SDF for this ${planType}`"
                class="px-2"
                @click="downloadSdf()">SDF</b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item v-if="canShowTool('download')"
                v-p-tooltip.top="'Download this account and all child components into an xls file'"
                @click="downloadAccount()">
                Download XLS
              </b-dropdown-item>
              <b-dropdown-item v-if="canShowTool('sdfDownload')"
                v-p-tooltip.top="`Download SDF for this ${planType}`"
                @click="downloadSdf()">
                Download SDF
              </b-dropdown-item>
            </template>
          </div>

          <template v-if="canShowTool('impressionTagSubmissions')">
            <li class="m-2">Impression Tag Submission:</li>
            <b-dropdown-item v-p-tooltip.top="'Preview Impression Tag Submissions'"
                             class="mx-5"
                             @click="impressionTagSubmissionClick('preview')">Preview</b-dropdown-item>
            <b-dropdown-item v-p-tooltip.top="`Download SDF for this ${planType}`"
                             class="mx-5"
                             @click="impressionTagSubmissionClick('submit')">Submit</b-dropdown-item>
          </template>

          <b-dropdown-item v-if="canShowTool('vehicleAdsChecklist') && vehicleAdChecklistCapability"
            @click="showVehicleAdsChecklist = true">
            Google Vehicle Ads Readiness
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('pinterestAdsChecklist') && pinterestChecklistCapability"
            @click="showPinterestChecklist = true">
            Pinterest Ads Readiness
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('analyzeCampaign')"
            @click="showAnalyzeCampaign = true">
            Analyze Campaign
          </b-dropdown-item>

          <pull-from-google v-if="canShowTool('reverseSync')" class="pull" buttonClass="dropdown-item" variant="link" :default-item="defaultItem" />

          <b-dropdown-item v-if="canShowTool('accountPerformance')" class="accountPerformance" @click="goToAccountPerformance" >
              Account Performance
          </b-dropdown-item>

        </b-dropdown-form>
      </b-dropdown>

      <!-- ACTIVITY & LOGS BUTTON DROPDOWN -->
      <b-dropdown :id="`whatfix-launch-change-history-button-${listingPage ? 'page' : 'drawer'}`"
                  :toggle-class="['whatfix-launch-change-history-button', {'p-0': buttonStyle, 'ml-2': !buttonStyle}]"
                  :variant="buttonStyle ? 'link-info' : 'light'"
                  :no-caret="buttonStyle" >
        <template #button-content>
          <template v-if="buttonStyle">
            <action-icon icon="qa" buttonText="Change History" caret/>
          </template>
          <template v-else>
            <fluency-icon type="qa"/>
            Change History
          </template>
        </template>

        <b-dropdown-item class="fluencyLogs"
                          @click="showChangeHistory = true">Change History
        </b-dropdown-item>

        <div data-toggle="collapse" :data-target="`#legacy-logs`" class="collapsed pr-2 dropdown-item dropdown-toggle" role="button" aria-expanded="false">Legacy Logs</div>
        <div class="collapse pl-4" :id="`legacy-logs`">
          <b-dropdown-item v-if="canShowTool('qaStatus')"
            @click="showAccountQa = true"
            class="position-relative">
            QA
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('userChangeLogs')"
            class="userActivity"
            @click="showUserChangeLog = true">User Activity</b-dropdown-item>


          <revision-history v-if="canShowTool('revisionHistory')"
                            class="history"
                            :title="historyTitle(planType)"
                            :type="planTypeMap[planType]"
                            :default-item="defaultItem" />

          <!-- For Budgets show a second History widget for `BudgetManagerConfiguration` -->
          <revision-history v-if="planType === 'budget'"
                            class="history"
                            :title="historyTitle('budgetManagerConfiguration')"
                            :type="planTypeMap['budgetManagerConfiguration']"
                            :default-item="defaultItem" />

          <b-dropdown-item v-if="canShowTool('historicReports')"
            class="historicReports"
            v-b-modal:historicReportModal>
            Report History
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('activity')"
            @click="showActivity = true"
            class="accountActivity">
            Activity
          </b-dropdown-item>

          <b-dropdown-item v-if="canShowTool('reverseSyncLogs')"
            class="rsLogs"
            @click="showReverseSyncModal = true">
            <!-- @click="$router.push('/insights/reverse-sync/?account=' + planId)"> -->
            Reverse Sync Logs
          </b-dropdown-item>

          <b-dropdown-item v-if="fluencyUser && canShowTool('triage')"
            class="triage"
            @click="showTriage = true">
            Triage Logs
          </b-dropdown-item>

          <div v-if="fluencyUser && canShowTool('serverLogs')" class="dropdown-item d-flex justify-content-between">
            <span @click="showServerLogs = true">Server Logs</span>
            <a :href="`/insights/logs/?accountPlanId=${item.accountPlanId}`"
              target="_blank">
              <fluency-icon type="linkOut" />
            </a>
          </div>

        </div>
      </b-dropdown>
    </div>

    <account-qa-status :show-account-qa="showAccountQa"
                       @update:show-account-qa="showAccountQa = $event"
      :manage-v2-listing-page="manageV2ListingPage"
      @hasIssues="hasQAIssues = $event" />

    <drawer :show="showUserChangeLog"
            @update:show="showUserChangeLog = $event"
            right
            title="User Activity"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            card-body-classes="pt-0">
      <user-change-log v-if="showUserChangeLog" :default-item="defaultItem"></user-change-log>
    </drawer>

    <drawer :show="showChangeHistory"
            @update:show="showChangeHistory = $event"
            right
            title="Change History"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="drawerWidth"
            card-body-classes="pt-0">
      <template #title>
        Change History <b-button variant="link-info" class="pl-0" :href="`/insights/change-history/?account=${item.accountPlanId}`" target="_blank"><fluency-icon type="linkOut"/></b-button>
      </template>
      <change-history v-if="showChangeHistory" :default-item="defaultItem" />
    </drawer>

    <drawer :show="showVehicleAdsChecklist"
            @update:show="showVehicleAdsChecklist = $event"
            right
            title="Am I ready for Google Vehicle Ads?"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            card-body-classes="pt-0">
      <vehicle-ads-checklist v-if="showVehicleAdsChecklist" :default-item="defaultItem" />
    </drawer>

    <drawer :show="showPinterestChecklist"
            @update:show="showPinterestChecklist = $event"
            right
            title="Am I ready for Pinterest Ads?"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            card-body-classes="pt-0">
      <pinterest-ads-checklist v-if="showPinterestChecklist" />
    </drawer>

    <drawer :show="showAnalyzeCampaign"
            @update:show="showAnalyzeCampaign = $event"
            right
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            card-body-classes="pt-0"
            @close="campaignProp = undefined">
      <template #title>
        Analyze Campaign: {{ campaignBeingAnalyzed }}
      </template>
      <analyze-campaign v-if="showAnalyzeCampaign" :campaign-plan-prop="campaignProp"
        @campaign-loaded="campaignBeingAnalyzed = $event"></analyze-campaign>
    </drawer>

    <drawer :show="showBlockedKeywords"
            @update:show="showBlockedKeywords = $event"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            right
            title="Blocked Keywords">
      <keep-alive>
        <blocked-keywords v-if="showBlockedKeywords" :default-item="defaultItem" />
      </keep-alive>
    </drawer>

    <drawer :show="showTriage"
            @update:show="showTriage = $event"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            right
            title="Triage Logs">
      <triage v-if="showTriage && planType && planId && planTypeMap[planType]"
        :plan-type="planTypeMap[planType]"
        :plan-id="planId" />
    </drawer>

    <drawer :show="showServerLogs"
            @update:show="showServerLogs = $event"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            right
            title="Server Logs">
      <log-viewer v-if="showServerLogs"
        :account-plan-id="item.accountPlanId" />
    </drawer>

    <drawer :show="showKeywordRecs"
            @update:show="showKeywordRecs = $event"
            :no-scrim="drawerNoScrim"
            :resizable="drawerResizable"
            :drawer-width="`${drawerWidth}`"
            right
            title="Keyword Recommendations">
      <notification-keyword-recs v-if="showKeywordRecs"
        hide-go-back></notification-keyword-recs>
    </drawer>

    <b-modal v-model="showReverseSyncModal" :title="`Reverse Sync Log for ${item.name}`" size="xxl" ok-only>
      <reverse-sync :account-id="item.accountPlanId" />
    </b-modal>

    <b-modal v-model="showActivity" :title="`Activity for ${item.name}`" size="xxl" ok-only>
      <account-activity />
    </b-modal>

    <b-modal id="historicReportModal" :title="`Report History for ${item.name}`" size="xxl" ok-only body-class="pt-0">
      <historic-report-table :account-plan-id="item.accountPlanId" height="calc(100vh - 285px)"></historic-report-table>
    </b-modal>

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import NotificationBadge from '../../common/notificationBadge'
import Drawer from 'core-ui/components/common/drawer.vue'
import InfoTooltip from '../../common/forms/infoTooltip'
import AccountActivity from '@/components/common/accountActivity'
import AnalyzeCampaign from '@/components/pages/manage/campaign/analyzeCampaign'
import NotificationKeywordRecs from '@/components/pages/alerts/alertChildHandlers/notificationKeywordRecs'
import LogViewer from '../../common/logViewer'
import HistoricReportTable from '@/components/pages/reporting/historicReportTable'
import ActionIcon from '@/components/pages/manage/actionIcon'
import AccountQaStatus from '@/components/accountQaStatus'

export default {
  name: 'toolboxDropdown',
  components: {
    ActionIcon,
    HistoricReportTable,
    LogViewer,
    NotificationKeywordRecs,
    AnalyzeCampaign,
    AccountActivity,
    ReverseSync: defineAsyncComponent(() => import('@/pages/insights/reverse-sync')),
    Triage: defineAsyncComponent(() => import('@/components/common/triage')),
    ShortkeyView: defineAsyncComponent(() => import('@/components/common/shortkeyView')),
    InfoTooltip,
    RevisionHistory: defineAsyncComponent(() => import('@/components/common/revisionHistory')),
    BlockedKeywords: defineAsyncComponent(() => import('./blockedKeywords')),
    UserChangeLog: defineAsyncComponent(() => import('@/components/common/userChangeLog')),
    ChangeHistory: defineAsyncComponent(() => import('@/components/common/changeHistory')),
    VehicleAdsChecklist: defineAsyncComponent(() => import('@/components/common/vehicleAdsChecklist')),
    PinterestAdsChecklist: defineAsyncComponent(() => import('@/components/common/pinterestAdsChecklist.vue')),
    Drawer,
    PullFromGoogle: defineAsyncComponent(() => import('@/components/common/pullFromGoogle')),
    AccountQaStatus,
    PastePlans: defineAsyncComponent(() => import('@/components/common/pastePlans')),
    NotificationBadge
  },
  props: {
    planType: {
      type: String,
      required: true
    },
    planId: {
      type: [String, Number],
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    listingPage: {
      // this prop might be hacky, it's currently used to determine if a drawer should be resizable, which
      // is dictated by whether or not it's a on a listing page while we have two versions of the Manage
      // page layout available.
      // 2024-09-6 GT: also using this to determine if we're in the main Manage Page or a Settings Drawer
      // to add a conditional identifier for WhatFix.
      type: Boolean,
      default: false
    },
    manageV2ListingPage: {
      // used to differentiate between main page and drawer content
      type: Boolean,
      default: false
    },
    capabilities: {
      type: Object,
      default: null
    },
    accountPlanId: {
      type: Number,
      default: null
    },
    activeChannels: {
      type: Array,
      default: null
    },
    buttonStyle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawerNoScrim: true,
      hasQAIssues: false,
      validPlanTypesForTools: {
        readOnly: ['account', 'campaign'],
        pastePlans: ['account', 'campaign', 'adgroup'],
        userChangeLogs: ['account', 'campaign', 'adgroup'],
        vehicleAdsChecklist: ['account', 'campaign'],
        pinterestAdsChecklist: ['account', 'campaign'],
        accountPerformance: ['account'],
        settingsReplicator: ['campaign'],
        qaStatus: 'all',
        blockedKeywords: 'all',
        reverseSyncLogs: ['account'],
        reverseSync: ['account'],
        triage: 'all',
        serverLogs: ['account'],
        activity: ['account'],
        download: ['account'],
        sdfDownload: ['account', 'campaign'],
        impressionTagSubmissions: ['account'],
        analyzeCampaign: ['campaign'],
        revisionHistory: ['account', 'campaign', 'adgroup', 'ad', 'creative', 'budget'],
        historicReports: ['account'],
        keywordRecs: ['account', 'campaign', 'adgroup']
      },
      validChannelsForTool: {
        keywordRecs: [1],
        blockedKeywords: [1]
      },
      showAccountQa: false,
      showUserChangeLog: false,
      showVehicleAdsChecklist: false,
      showPinterestChecklist: false,
      showActivity: false,
      showAnalyzeCampaign: false,
      planTypeMap: {
        budget: 'BudgetPlan',
        budgetManagerConfiguration: 'BudgetManagerConfiguration',
        account: 'AccountPlan',
        campaign: 'CampaignPlan',
        adgroup: 'AdGroupPlan',
        adGroup: 'AdGroupPlan',
        keyword: 'KeywordPlan',
        creative: 'CreativePlan',
        ad: 'CreativePlan'
      },
      showReverseSyncModal: false,
      accountDownloading: false,
      campaignBeingAnalyzed: '',
      campaignProp: undefined
    }
  },
  mounted () {
    if (this.customerSettings.length < 1) {
      this.$store.dispatch('fetchCustomerSettings')
    }
    this.$eventBus.$on('open-analyze-campaign', this.setShowAnalyzeCampaign)
  },
  beforeUnmount () {
    this.$eventBus.$off('open-analyze-campaign', this.setShowAnalyzeCampaign)
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    userCapabilities () {
      return this.user?.capabilities || {}
    },
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    toolboxBadgeColor () {
      return this.hasQAIssues || 'primary'
    },
    defaultItem () {
      return this.manageV2ListingPage ? 'activeItem' : 'settingsItem'
    },
    item () {
      return this.$store.getters[this.defaultItem] || {}
    },
    hasRegenerateScreenshotsCustomerSetting () {
      const filteredCustomerSettings = this.$store.getters.customerSettings.filter(setting => { return setting.key === 'CREATIVE_SCREENSHOTS' })
      return filteredCustomerSettings.length > 0 && filteredCustomerSettings[0].values[0]
    },
    customerSettings () {
      return this.$store.getters.customerSettings
    },
    vehicleAdChecklistCapability () {
      if (this.capabilities && Object.keys(this.capabilities).length > 0) {
        return !!this.capabilities.GoogleVehicleAds
      } else {
        return !!this.item?.capabilities?.GoogleVehicleAds
      }
    },
    pinterestChecklistCapability () {
      if (this.capabilities && Object.keys(this.capabilities).length > 0) {
        return !!this.capabilities.Pinterest
      } else {
        return !!this.item?.capabilities?.Pinterest
      }
    },
    drawerResizable () {
      if (this.listingPage || this.manageV2ListingPage) {
        return true
      }
      return false
    },
    drawerWidth () {
      if (!this.manageV2ListingPage) {
        return 'calc(100% - 20px)'
      }

      return '75vw'
    },
    dropdownId () {
      return `${this.planType}_toolbox`
    },
    showToolboxNotificationBadge () {
      return !!this.hasQAIssues
    },
    fluencyUser () {
      return this.$store.getters.isFluencyUser
    },
    showBlockedKeywords: {
      get () {
        return this.$route.query?.tool === 'blockedKeywords'
      },
      set (val) {
        if (val) {
          this.$router.push({ query: { ...(this.$route.query || {}), tool: 'blockedKeywords' } })
        } else if (this.$route.query.tool) {
          const query = { ...(this.$route.query || {}) }
          delete query.tool
          this.$router.replace({ query })
        }
      }
    },
    showKeywordRecs: {
      get () {
        return this.$route.query?.tool === 'keywordRecs'
      },
      set (val) {
        if (val) {
          this.$router.push({ query: { ...(this.$route.query || {}), tool: 'keywordRecs' } })
        } else if (this.$route.query.tool) {
          const query = { ...(this.$route.query || {}) }
          delete query.tool
          this.$router.replace({ query })
        }
      }
    },
    showTriage: {
      get () {
        if (!this.fluencyUser) {
          return false
        }
        return this.$route.query?.tool === 'triage'
      },
      set (val) {
        if (val) {
          this.$router.push({ query: { ...(this.$route.query || {}), tool: 'triage' } })
        } else if (this.$route.query.tool) {
          const query = { ...(this.$route.query || {}) }
          delete query.tool
          this.$router.replace({ query })
        }
      }
    },
    showServerLogs: {
      get () {
        if (!this.fluencyUser) {
          return false
        }
        return this.$route.query?.tool === 'serverLogs'
      },
      set (val) {
        if (val) {
          this.$router.push({ query: { ...(this.$route.query || {}), tool: 'serverLogs' } })
        } else if (this.$route.query.tool) {
          const query = { ...(this.$route.query || {}) }
          delete query.tool
          this.$router.replace({ query })
        }
      }
    },
    showChangeHistory: {
      get () {
        return this.$route.query?.tool === 'changeHistory'
      },
      set (val) {
        if (val) {
          this.$router.push({ query: { ...(this.$route.query || {}), tool: 'changeHistory' } })
        } else if (this.$route.query.tool) {
          const query = { ...(this.$route.query || {}) }
          delete query.tool
          this.$router.replace({ query })
        }
      }
    },
    supportsSdfDownload () {
      const channels = this.$store.getters.advertisingChannels

      const accountChannels = this.activeChannels && this.activeChannels.some(ch => {
        return [26, 27, 28, 31].includes(ch)
      })

      const customerChannels = channels.some(ch => {
        return [26, 27, 28, 31].includes(ch?.advertisingChannelId)
      })

      return accountChannels && customerChannels
    }
  },
  methods: {
    advancedBulkEdit () {
      this.$store.commit('bulkEdit/planIds', { [`${this.planType}PlanIds`]: [this.item[`${this.planType}PlanId`]] })
      this.$store.commit('bulkEdit/returnPath', this.$route.fullPath)
      this.$router.push({ path: '/bulk' })
    },
    goToAccountPerformance () {
      this.$router.push({ path: '/insights/account-performance/', query: { account: this.planId } })
    },
    showSettingsReplicator (event) {
      this.$emit('showSettingsReplicator', true)
    },
    toggleReadOnly () {
      this.$emit('toggleReadOnly')
      this.$refs.toolboxDropdown.hide(true)
    },
    toggleShowUserChangeLog () {
      this.$setCompat(this, 'showUserChangeLog', !this.showUserChangeLog)
    },
    toggleShowVehicleAdsChecklist () {
      this.$setCompat(this, 'showVehicleAdsChecklist', !this.showVehicleAdsChecklist)
    },
    trackToolboxEvents (event) {
      const item = event.target.closest('.dropdown-item:not(.disabled)')
      if (!item) return

      // Toolbox or Activity & Logs
      const dropdown = item.closest('.dropdown')?.querySelector('button')?.innerText?.trim()

      const label = item.innerText?.trim()

      this.trackToolboxItem(dropdown, label, item)
    },
    trackToolboxItem (dropdown, label, element) {
      // remove special characters from settings replicator label
      if (label.includes('Settings Replicator')) {
        label = 'Settings Replicator'
      }
      this.$track.event('toolbox item', {
        dropdown,
        dropdownItem: label,
        path: this.$route.fullPath,
        drawerOpen: this.$route?.query?.hasOwnProperty('settingsItemId'),
        settingsItemType: this.$route?.query?.settingsItemType
      }, 'manage', element)
    },
    canShowHint (tool) {
      if (this.manageV2ListingPage) {
        switch (tool) {
          case 'readOnly':
          case 'settingsReplicator':
            return true
        }
      }

      return false
    },
    canShowTool (tool) {
      if (!tool || !this.validPlanTypesForTools[tool]) {
        return false
      }

      if (!this.toolIsValidForChannel(tool)) {
        return false
      }

      if (this.validPlanTypesForTools[tool] === 'all') {
        return true
      }

      if (this.manageV2ListingPage) {
        switch (tool) {
          case 'readOnly':
            return false
        }
      }

      if (tool === 'sdfDownload') {
        return this.supportsSdfDownload
      }

      if (tool === 'impressionTagSubmissions') {
        return this.userCapabilities?.GoogleImpressionTagSubmission
      }

      return this.validPlanTypesForTools[tool].includes(this.planType.toLowerCase())
    },
    toolIsValidForChannel (tool) {
      const validChannels = this.validChannelsForTool[tool]
      if (!validChannels) return true

      if (this.item.advertisingChannelId) {
        return validChannels.includes(this.item.advertisingChannelId)
      } else if (this.item.activeChannels) {
        return this.item.activeChannels.filter(ch => validChannels.includes(ch)).length > 0
      }
      return true
    },
    async impressionTagSubmissionClick (requestType = 'preview') {
      const payload = {
        accountPlanId: this.planId
      }

      if (requestType === 'preview') {
        await this.$res.customer.previewImpressionTagSubmission(payload)
      }
      if (requestType === 'submit') {
        const areYouSure = await this.$confirm('Are you sure you want to submit this Impression Tag Submission?')
        if (areYouSure) {
          await this.$res.customer.submitImpressionTagSubmission(payload)

          await this.$alert('You should receive an email with the results of your submission within a few minutes.')
        }
      }
    },
    async downloadSdf () {
      const payload = {}

      if (this.planType === 'account') {
        payload.accountPlanId = this.planId
        await this.$res.customer.getSdf('account', payload)
      } else if (this.planType === 'campaign') {
        payload.accountPlanId = this.accountPlanId
        payload.campaignPlanId = this.planId
        await this.$res.customer.getSdf('campaign', payload)
      }
    },
    async downloadAccount () {
      this.$refs.toolboxDropdown.hide(true)
      this.accountDownloading = true
      const selectedExportTypes = ['accounts', 'flags', 'groups', 'budgets', 'campaigns', 'adgroups', 'sitelinks', 'callouts', 'locationextensions', 'priceextensions', 'callextensions', 'structuredsnippet', 'promotionextensions', 'imageextensions', 'keywords', 'ads']
      await this.$res.fetch.accountExcelSheetDownload([this.planId], true, selectedExportTypes)
      this.accountDownloading = false
    },
    setShowAnalyzeCampaign (item) {
      this.campaignProp = item
      this.showAnalyzeCampaign = true
    },
    historyTitle (type) {
      switch (type) {
        case 'budget':
          return 'Budget History'
        case 'budgetManagerConfiguration':
          return 'Config History'
        default:
          return 'History'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.bounce-enter,
.bounce-leave-to {
  opacity: 0;
  transform: rotateY(50deg);
}

.bounce-enter-to,
.bounce-leave {
  opacity: 1;
  transform: rotateY(0deg);
}

.bounce-enter-active,
.bounce-leave-active {
  transition: opacity, transform 200ms ease-out;
}
</style>
<style lang="scss">
.toolbox-dropdown {
  .dropdown-toggle[data-toggle="collapse"]:before {
    display: none;
  }
  .inline-icons {
    button .fluency-icon {
      display: block;
    }
  }
}
</style>
